.catalog-category-view, .catalogsearch-result-index, .page-layout-1column .homepage--featured .block.widget {

	.sections.nav-sections {
		margin-bottom: 0px;
	}

}
.catalog-category-view .products-grid, .catalogsearch-result-index .products-grid, .page-layout-1column .homepage--featured .block.widget {
	 .product-item {

		border: 1px solid $color-grey-primary;
		position: relative;

		.actions-secondary {
			position: absolute;
			top: -1px;
			left: -1px;
			padding: 0px;
			z-index: 9999;

			a {
				border: 1px solid $color-grey-primary !important;
				margin: 0px;
				display: block !important;
				float: left;
				background: none;
			}

			&:after {
				clear: both;
				float: none;
			}
		}

		/*.action.tocompare {
			&:before {

				content: "";
				background: url('../images/compare-icon.png');
				background-repeat: no-repeat;
				background-position: 50%;
				width: 25px;
				height: 25px;

			}

			&:hover {
				&:before {

					content: "";
					background: url('../images/compare-icon-focus.png');
					background-repeat: no-repeat;
					background-position: 50%;
					width: 25px;
					height: 25px;

				}
			}
		}*/

		.product-item-details {

			text-align: center;
			margin-top: -24px;

			.product-item-name {
				margin-left: 10px;
				margin-right: 10px;
				text-align: center;
				vertical-align: middle;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 0px;
				font-size: 15px;

				a {
					color: #333;
					text-decoration: none;
					font-weight: bold;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

			.product-item-sku {
				font-size: 1.2rem;

				a {
					color: #333;
					text-decoration: none;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

		}

		.product-image-container span.product-image-wrapper {
			padding-bottom: 110%;
		}

		.price-box {
			margin: 5px 0 15px;
		}

		.product-image-photo {
			max-width: 80%;
		}

		.price-box .price-excluding-tax .price {
			font-size: 2.5rem;
			font-weight: normal;
		}

		.price-including-tax {
			display:none;
		}

		.price-excluding-tax {

			&:before {
				display: none;
			}

			&:after {
				content: "(" attr(data-label) ")";
		    	font-size: 1.1rem;
					display: block;
					margin-top: 5px;
			}
		}
	}

	.column.main, .sidebar-main .block.filter {
		padding-top: 40px;
	}

	.toolbar-products {
		padding: 0px;
		margin-bottom: -10px
	}

	.product-item-actions {
		.actions-primary {
			display: none;
		}
	}

}

.product-item-actions .actions-primary + .actions-secondary > .action {
	margin-right: -1px !important;
}

@include max-screen($screen__m) {

	.page-layout-2columns-left .page-main .columns {
		flex-direction: column;

		.page-title-wrapper {
			margin-top: 30px;
		}

		.column.main {
			flex: 1;

			.product-items {
				margin-left: 0px;
				margin: 0px;
			}

			.products-grid .product-item {
				display: inline-block;
				width: 46%;
				margin-left: 2%;
				margin-bottom: 5%;
			}
		}

		.sidebar.sidebar-main {
			flex: 1;

			&:before {
				content: "";
				background-color: #F8F8F8;
				position: absolute;
				height: 100%;
				width: 2000px;
				left: -2000px;
				z-index: -1;
				display: none !important;
			}

			#layered-filter-block {
				margin: 0px;
				padding: 20px;

				.block-actions.filter-actions {
					display: block;
					float: none;
					margin-top: 40px;
					margin-bottom: 20px;
				}
			}
		}
	}


}

.catalog-category-view .column.main, .catalog-category-view .sidebar-main .block.filter, .catalogsearch-result-index .column.main, .catalogsearch-result-index .sidebar-main .block.filter, .page-layout-1column .homepage--featured .block.widget .column.main, .page-layout-1column .homepage--featured .block.widget .sidebar-main .block.filter {
	padding-top: 40px;
}

.filter-options {
	font-size: 13px;
	line-height: 25px;
}

.sorter-options {
	background: #fff;
   background-clip: padding-box;
   border: 1px solid #c2c2c2;
   border-radius: 1px;
   font-family: 'montserrat';
   font-size: 13px;
   padding: 5px;
}

.sorter-label {
	font-size: 13px;
}