// -----------------------------------------------------------------------------------
//
// Search Results Page.

.mst-search {
    &__highlight {
        background: #ffff00;
    }

    &__index {
        ul {
            list-style: none;
            margin-bottom: 10px;
            padding-left: 0;

            li {
                // .lib-clearfix();
                float: left;

                margin: 2rem 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .mst-search__index-title {
                    font-size: 1.5rem;
                    font-weight: 600;

                    >a {
                        color: #333333;
                    }

                    >span {
                        color: #aaaaaa;
                    }

                    &._crumbs {
                        a {
                            &:after {
                                content: "";
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='h-auto pos-relative mh-4 css-10v800z'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
                                display: inline-block;
                                width: 13px;
                                height: 13px;
                                margin: -2px 5px;
                            }

                            &:last-child {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .mst-search__index-content {
                    color: #666666;
                    margin: .5rem 0 0 0;
                }

                .mst-search__index-image {
                    margin-right: 2rem;
                    float: left;

                    >img {
                        max-width: 5rem;
                        max-height: 5rem;
                    }
                }
            }
        }
    }

    &__misc-recommendations {
        display: flex;
        flex-wrap: wrap;

        dt {
            margin-right: 20px;
        }

        dd {
            margin-right: 20px;

            .count {
                color: #7d7d7d;
            }
        }
    }

    &__result-tabs {
        // .lib-clearfix();
        float: left;

        list-style: none;
        border-bottom: 1px solid #ccc;
        padding: 0;

        li {
            margin: 0 5px -1px 0;
            padding: 0;
            float: left;

            a {
                display: block;
                padding: 10px 35px;
                text-decoration: none;
                color: #7d7d7d;
                background: #f6f6f6;
                border: 1px solid #ccc;
                border-radius: 1px 1px 0 0;

                &.active,
                &:hover,
                &:focus {
                    color: #333333;
                    background: #ffffff;
                }

                &.active {
                    border-bottom: 1px solid #ffffff;
                }
            }
        }
    }

    &__result-index {
        padding: 1rem 0 1rem 0;

        .mst-search__result-index-title {
            background: #f6f6f6;
            padding: 1rem;
            border-radius: 1px;
            color: #7d7d7d;
        }

        &:last-child {
            margin-bottom: 2.5rem;
            border-bottom: 1px solid #f6f6f6;
        }
    }
}


@include max-screen($screen__m) {
    .mst-search {
        &__result-tabs {
            li {
                float: none;
                display: block;
                width: 100%;

                a {
                    &.active {
                        border: 1px solid #cccccc;
                    }
                }
            }
        }
    }
}



// -----------------------------------------------------------------------------------
//
// Search AutoComplete

$spinner-color: #999999;

$header-bg: #efefef;
$hr-color: #f6f6f6;
$hover-bg: #f8f8f8;
$mute-color: #777777;
$text-color: #333333;
$higlight-color: #1ba1fc;

.mst-searchautocomplete {
    &__autocomplete {
        display: none !important;
        position: absolute;
        top: 5.3rem !important;
        left: 0 !important;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 3px;
        min-height: 3.5rem;
        z-index: 1000;
        text-align: left;
        overflow: hidden;
        box-shadow: 0px 1px 2px rgb(0, 0, 0 / 30%);
        margin-top: 7px;

        &._active {
            display: block !important;
        }

        &._loading {
            .mst-searchautocomplete__spinner {
                display: block;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    &__close {
        display: none;
    }

    &__spinner {
        display: none;
        position: absolute;
        top: 5px;
        right: 10px;
    }

    &__empty-result {
        background: $header-bg;
        text-align: center;
        padding: 8px 10px;
        color: $mute-color;
        font-size: 1.2rem;
        display: block;
    }

    &__show-all {
        border-top: 1px solid $header-bg;
        text-align: center;
        margin: 0 10px;

        a {
            font-weight: 600;
            padding: 10px 0;
            color: $mute-color;
            font-size: 1.2rem;
            display: block;
        }
    }

    &__index-title {
        border-bottom: 1px solid $header-bg;
        padding: 10px 0 9px 0px;
        margin: 0 10px;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $mute-color;
        text-transform: uppercase;
        font-weight: 700;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 70vh;
        overflow: auto;

        li {
            // .lib-clearfix();
            float: left;
            margin: 0;
            padding: 1rem;
            cursor: pointer;

            a.title {
                font-weight: 600;
                margin-bottom: 0;
                color: $text-color;
                text-decoration: none;
            }

            &._active {
                background: $hover-bg;

                a.title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__index {
        &.popular {
            .index-title {
                background: none;
                text-align: left;
                color: #999999;
            }

            ul {
                li {
                    float: left;
                    padding: 5px 10px;
                    margin: 0.5em;
                    background: #347cc3;
                    border-radius: 4px;

                    a {
                        font-size: 1.3rem;
                        color: #fff;
                        text-decoration: none;
                    }

                    &:hover {
                        background: darken(#347cc3, 5%);
                        cursor: pointer;

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &__item {
        &.magento_catalog_product {
            display: flex;
            border-bottom: 1px solid $hr-color;
            align-items: center;
            padding: 0.5em;
            margin: 0;

            &:last-child {
                border-bottom: none;
            }

            .mst-product-image-wrapper {
                height: 8rem;
                width: 8rem;
                margin-right: 1rem;

                img {
                    height: 8rem;
                    max-width: 8rem;
                    display: block;
                    margin: auto;
                    object-fit: contain;
                }
            }

            .title {
                display: block;
                margin-bottom: 0.5rem;

                a {
                    color: $text-color;
                }

                .sku {
                    color: $mute-color;
                }
            }

            .meta {
                flex-grow: 1;
                overflow: hidden;
            }

            .description {
                font-size: 1.2rem;
                color: $mute-color;
                height: 3rem;
                line-height: 1.5rem;
                overflow: hidden;
                margin-bottom: 0.5rem;
            }

            .price {
                display: block;
                margin-left: 1rem;
                white-space: nowrap;
                font-size: 1.3rem;
                text-align: right;

                .price-label {
                    margin-right: 1rem;
                }

                .price {
                    font-weight: 600 !important;
                }
            }

            .product-reviews-summary {
                margin: 0;

                .reviews-actions {
                    display: none;
                }
            }

            .to_cart_message {
                padding: 5px 10px;
                font-size: 1.2rem;

                &.error {
                    color: #e02b27;
                    background: #fae5e5;
                }

                &.success {
                    color: #006400;
                    background: #e5efe5;
                }
            }

            .to-cart {
                float: right;
                margin-top: .5rem;
                margin-bottom: .5rem;
            }

            .inStock {
                color: #76bd76;
            }

            .outOfStock {
                color: #a96565;
            }

            &._active {
                background: $hover-bg  !important;

                .title {
                    a {
                        text-decoration: underline;
                    }
                }
            }

        }

        &.magento_search_query {
            a {
                float: left;
            }

            .num_results {
                float: right;
                color: $mute-color;
            }
        }

        a.title {
            word-break: break-word;

            i {
                color: $mute-color;
                display: inline-block;
                margin: 0 .5rem;
                font-style: normal;

            }

        }
    }

    &__highlight {
        font-weight: 600;
        color: #1ba1fc;
    }

    &__spinner {
        $size: 20px;
        position: relative;
        width: $size;
        height: $size;
        margin: auto;

        .spinner-item {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: $size;
            height: $size;
            margin: 0;
            border: 2px solid #ffffff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $spinner-color transparent transparent transparent;

            &.spinner-item-1 {
                animation-delay: -0.45s;
            }

            &.spinner-item-2 {
                animation-delay: -0.3s;
            }

            &.spinner-item-3 {
                animation-delay: -0.15s;
            }
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.mst-search-autocomplete__typeahead-overlay {
    color: #aaa;
    top: 0;
    position: absolute !important;
    z-index: -1;
    left: 15px;
    border: 0 !important;
}

@include min-screen($screen__m) {
    .mst-searchautocomplete {
        &__autocomplete {
            width: 100%;
            min-width: 480px;

            &._active {
                display: block !important;

                &.mst-2-cols {
                    width: 720px !important;
                    position: absolute;

                    .mst-searchautocomplete__index {
                        clear: left;
                        float: left;
                        display: block;
                        position: relative;
                        max-width: 240px;
                        width: 240px;
                        padding-bottom: 2rem;
                        box-sizing: border-box;
                    }

                    div.magento_catalog_product {
                        width: 480px;
                        max-width: 480px;
                        clear: right;
                        float: right;
                        border-left: 1px solid #f6f6f6;
                        border-right: unset;
                        padding-bottom: 0;
                    }

                    .mst-searchautocomplete__show-all {
                        clear: both;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .mst-searchautocomplete {
        &__autocomplete {}

        &__close {
            display: block;
            position: absolute;
            right: 6px;
            top: 0;
            font-weight: bold;
            color: #999999;
            font-size: 1.6rem;
            padding: 10px;
            line-height: 1rem;
            cursor: pointer;
        }
    }
}


// DESKTOP
#search_autocomplete,
.search-autocomplete,
#searchbox_autocomplete {
    display: none !important;
    height: 0 !important;
    overflow: hidden !important;
    ;
}

// compatibility with porto theme
.block-search {
    z-index: 90;
}



// -----------------------------------------------------------------------------------
//
// In Page Miravsit Search

$selection-color: #ff5501;
$selection-color: #1ba1fc;

html.mstInPage,
html.mstInPage body {
    position: absolute;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.mstInPage {
    &__container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        display: none;
        overflow-y: scroll;

        &._visible {
            display: block;
        }
    }

    &__backdrop {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(240, 243, 246, 0.5);
        width: 100%;
        height: 100%;
        z-index: 1000000;
    }

    &__wrapper {
        position: relative;
        z-index: 1000001;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        margin: 0 auto;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        box-sizing: border-box;
        background: #ffffff;

        form {
            width: 100%;
            display: flex;
            align-items: center;

            &:before {
                display: block;
                content: "";
                width: 30px;
                height: 30px;
                flex-shrink: 0;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg enable-background='new 0 0 32 32' height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='play'/%3E%3Cg id='stop'/%3E%3Cg id='pause'/%3E%3Cg id='replay'/%3E%3Cg id='next'/%3E%3Cg id='Layer_8'/%3E%3Cg id='search'%3E%3Cg%3E%3Ccircle cx='14.5' cy='14.5' fill='none' r='11.5' stroke='%23CCCCCC' stroke-miterlimit='10' stroke-width='2'/%3E%3Cline fill='none' stroke='%23CCCCCC' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' x1='29' x2='23' y1='29' y2='23'/%3E%3C/g%3E%3C/g%3E%3Cg id='list'/%3E%3Cg id='love'/%3E%3Cg id='menu'/%3E%3Cg id='add'/%3E%3Cg id='headset'/%3E%3Cg id='random'/%3E%3Cg id='music'/%3E%3Cg id='setting'/%3E%3Cg id='Layer_17'/%3E%3Cg id='Layer_18'/%3E%3Cg id='Layer_19'/%3E%3Cg id='Layer_20'/%3E%3Cg id='Layer_21'/%3E%3Cg id='Layer_22'/%3E%3Cg id='Layer_23'/%3E%3Cg id='Layer_24'/%3E%3Cg id='Layer_25'/%3E%3Cg id='Layer_26'/%3E%3C/svg%3E");
            }
        }

        .mstInPage__close {
            height: 32px;
            width: 32px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='cross'%3E%3Cline class='cls-1' x1='7' x2='25' y1='7' y2='25' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3Cline class='cls-1' x1='7' x2='25' y1='25' y2='7' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3C/g%3E%3C/svg%3E");
            cursor: pointer;
            flex-shrink: 0;
        }

        &._loading {
            form {
                &:before {
                    width: 26px;
                    height: 26px;
                    margin-left: 2px;
                    margin-right: 2px;
                    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.542 1.487A21.507 21.507 0 00.5 22c0 11.874 9.626 21.5 21.5 21.5 9.847 0 18.364-6.675 20.809-16.072a1.5 1.5 0 00-2.904-.756C37.803 34.755 30.473 40.5 22 40.5 11.783 40.5 3.5 32.217 3.5 22c0-8.137 5.3-15.247 12.942-17.65a1.5 1.5 0 10-.9-2.863z' fill='%23CCCCCC' %3E%3C/path%3E%3C/svg%3E");
                    animation: mstInPage--loading .5s linear infinite;
                }
            }
        }

        input[type=search] {
            border: none;
            font-size: 2.3rem;
            color: #575757;
            height: 60px;
            background: #ffffff;
            margin-right: 30px;
            padding-left: 30px;
            width: 100%;

            &::placeholder {
                color: #cccccc;
            }

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }

    &__indexList {
        display: flex;

        .mstInPage__index {
            padding: 7px 10px;
            cursor: pointer;
            border-radius: 2px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            margin-right: 10px;

            span,
            i {
                display: block;
            }

            span {
                font-weight: 600;
                line-height: 2rem;
                font-size: 1.4rem;
            }

            i {
                font-style: normal;
                font-size: 1.2rem;
                color: #999999;
                margin-left: 1rem;
                line-height: 2rem;
            }

            &:hover {
                border-color: $selection-color;
            }

            &._active {
                background: $selection-color;
                border-color: $selection-color;
                color: #ffffff;

                i {
                    color: #ffffff;
                }
            }

            &._empty {
                display: none;
                opacity: 0.3;
                border-color: transparent !important;
            }
        }
    }

    &__itemList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 30px;
        //
        //&.magento_catalog_product {
        //    grid-template-columns: 1fr 1fr; // 1fr;
        //}

        &._empty {
            background: #fdf0d5;
            color: #6f4400;
            padding: 15px;
            font-size: 90%;
        }
    }

    &__highlight {
        color: #1ba1fc;
        font-weight: 600;
    }

    &__item {
        &.magento_catalog_product {
            display: flex;

            .mst__product-image {
                width: 130px;
                flex-shrink: 0;
                margin-right: 20px;
                background: #ffffff;

                img {
                    border: 1px solid #ececec;
                    padding: 5px;
                    max-width: 120px;
                }
            }

            .mst__product-meta {
                .mst__title {
                    color: #333333;
                    margin-bottom: 10px;
                    display: block;
                }

                .mst__description {
                    font-size: 1.2rem;
                    color: #999999;
                    height: 4.5rem;
                    line-height: 1.5rem;
                    overflow: hidden;
                    margin-bottom: 10px;
                }

                .reviews-actions {
                    font-size: 12px;
                }
            }

            .mst__product-cart {
                display: flex;
                //flex-direction:  row;
                align-items: center;
                justify-content: flex-end;
                text-align: center;
                //margin-left:     20px;
                //width:           15%;
                flex-shrink: 0;

                .price-container {
                    display: flex;
                    align-items: center;
                }

                .price-label {
                    font-size: 1.2rem;
                    color: #999999;
                    font-weight: 500;
                    display: block;
                    margin-right: 10px;
                }

                .price {
                    font-weight: 500 !important;
                }
            }
        }

        &.magento_catalog_category,
        &.magento_cms_page {
            padding: 10px;
            border: 1px solid #ececec;

            .title {
                i {
                    font-style: normal;
                    color: #999999;
                    margin: 0 10px;
                }
            }
        }
    }

    &__content {
        display: flex;
        background: #ffffff;
        border-top: 1px solid #ececec;
        flex-direction: column;

        &._empty {
            display: none;
        }

        .mstInPage__sidebar {
            flex-grow: 1;
        }

        .mstInPage__main {
            padding: 30px;
            flex-grow: 1;
        }
    }

    &__bucketList {
        overflow-x: scroll;

        .mstInPage__bucket {
            //margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            strong {
                padding: 20px 0 20px 30px;
                display: none;
            }

            .mstInPage__bucketItemList {
                display: flex;
                padding: 30px;
                border-bottom: 1px solid #ececec;

                .mstInPage__bucketItem {
                    border-radius: 2px;
                    margin-right: 12px;
                    padding: 6px 12px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    white-space: nowrap;
                    justify-content: space-between;
                    border: 1px solid #ececec;
                    user-select: none;

                    i {
                        display: block;
                        font-style: normal;
                        margin-left: 10px;
                        color: #999999;
                        //padding:       2px 8px;
                        //border-radius: 3px;
                        //font-size:   90%;
                    }

                    &:hover {
                        border-color: $selection-color;
                    }

                    &._active {
                        background: $selection-color;
                        border-color: $selection-color;
                        color: #ffffff;

                        i {
                            color: #eeeeee;
                        }
                    }
                }
            }
        }
    }

    &__stat {
        height: 20px;
        text-align: right;
        font-size: 12px;
        color: #6d6d6d;
        margin-bottom: 10px;
        margin-top: -18px;
        transition: all 100ms;

        &._loading {
            opacity: 0.3;
        }
    }

    @keyframes mstInPage--loading {
        to {
            transform: rotate(1turn)
        }
    }

    @media (min-width: 1440px) {
        &__wrapper {
            margin: 2% auto;
            max-width: 1420px;
        }
    }
}

@include max-screen($screen__m) {
    .mstInPage {
        &__wrapper {
            margin: 0;
            max-width: 100%;

            .mstInPage__header {
                flex-direction: column;
                padding: 10px;

                .mstInPage__indexList {
                    display: none;
                }
            }

            .mstInPage__main {
                padding: 10px;

                .mstInPage__itemList {
                    grid-template-columns: 1fr;
                }

                .mstInPage__stat {
                    display: none;
                }
            }

            .mstInPage__sidebar {
                .mstInPage__bucketItemList {
                    padding: 10px;
                    overflow-x: scroll;
                }
            }
        }
    }
}
