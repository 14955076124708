
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    font-size: 1.5rem;
}

a, .alink {

    color: $color-blue-primary;

    &:hover, &:focus {
        color: darken($color-blue-primary, 10%);
    }
}

#cookie-status { display:none; }

.page-layout-2columns-left {
    .page-main {

        .columns {
            display: flex;

            .main {
                flex: 0.8;
            }

            .sidebar {

                flex: 0.2;
                background: #F8F8F8;
                margin-right: 2%;

                &:before {
                    content: "";
                    background-color: #F8F8F8;
                    position: absolute;
                    height: 100%;
                    width: 2000px;
                    left: -2000px;
                    z-index: -1;
                }
            }
        }

    }
}

.page-layout-1column {
    .page-main {

        .columns {
            display: flex;

            .main {
                flex: 1;
            }

        }
    }
}

.page-footer {
    background: #F8F8F8;
}

/* Grid Listing */

@include min-screen($screen__l) {

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 24%) / 5);
                padding: 0;
                width: 19%;

                &:nth-child(4n + 1) {
                    margin-left: calc((100% - 4 * 24%) / 5);
                }

                &:nth-child(5n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

}


/* Buttons */

.action.primary {
    text-transform: uppercase;
    border-radius: 8px !important;
    padding: 10px 20px;
    box-shadow: none !important;
}


/* loader */

.minicart-wrapper {

    .loading-mask {
        position: absolute;
        width: 15px;
        height: 14px;
        bottom: -10px;
        right: -6px;
        border-radius: 15px;
        background-color: #347CC3;

        .loader {
            position: relative;

            &:before {
                animation: fa-spin 2s linear infinite;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 700;
                content: "\f1ce";
                position: absolute;
                font-size: 1.3rem;
                color: #efefef;
                -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                top: -4px;
                right: 0px;
            }

            img {

                display: none;

            }
        }
    }
}

/* Mini Cart */

.minicart-wrapper .block-minicart {
    right: 25px;
    margin-top: 10px;


    .action.close{
        display: none;
    }

    .amount .price-wrapper:first-child .price {
        font-size: 1.5rem;
    }

    .block-content > .actions > .primary .action.primary {
        font-size: 1.5rem;
    }

    .action.edit::before, .minicart-items .action.edit::before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 700;
        content: "\f303";
        font-size: 1.2rem;

    }

    .action.edit::before, .minicart-items .action.delete::before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 700;
        content: "\f1f8";
        font-size: 1.2rem;

    }

    @include max-screen($screen__m) {
        width: 350px;
        right: -60px;
        margin-top: 25px;

        &:after {
            right: 61px !important;
        }

        &:before {
            right: 61px !important;
        }
    }
}

/* Search */

.amsearch-results {
    border: 1px solid $color-grey-primary;
    border-radius: 0px 0px 8px 8px;
    margin-top: 9px;

    .amasty-xsearch-block-header {
        &:before {
            display: none;
        }
        padding-left: 0px !important;
    }

    .actions-secondary {
        display: none !important;
    }
}

.amsearch-products .amsearch-highlight {
    font-weight: bold !important;
    font-style: italic !important;
    color: initial !important;
    background: none !important;

}

.form .search-autocomplete {

    @include max-screen($screen__m) {
        margin: 0px auto !important;
        top: 52px !important;
    }

}

@include max-screen($screen__m) {

    /*.amsearch-wrapper-content .product-item-details {
        min-height: 65px !important;
    }
    .amsearch-wrapper-content .product-item-actions {
        margin-top: -8px !important;
        right: 40px !important;
    }*/

    .amsearch-results .amsearch-item:hover {
        background: #fff !important;
    }

    .amsearch-results .amsearch-products {
        background: #fff !important;
    }

    .amsearch-wrapper-content .amsearch-wrapper-inner {
        align-items: flex-start !important;
        flex-direction: column !important;
    }

    .amsearch-products {
        .amasty-xsearch-block-header {
            padding-left: 10px;
        }
    }

}

.amsearch-results .amsearch-item:hover {
    background: #fff !important;
}

.amsearch-results .amsearch-products {
    background: #fff !important;
}


.amsearch-link {
    background: $color-blue-primary !important;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    text-transform: uppercase !important;

    &:hover {
        background: #006bb4 !important;
    }
}

@include min-screen($screen__l) {
    .amsearch-results .amsearch-products {
        background: #fff !important;
        border-left: 1px solid #efefef !important;
        padding: 20px !important;
    }

    .amsearch-form-container.-large .amsearch-wrapper-content {
        padding: 0px !important;
    }

    .product-item-actions .actions-primary {
        margin-top: 10px !important;
    }


}

/* Checkout */


.firecheckout {

    .page-title-wrapper {
        margin-top: 40px !important;

        h1 {
            font-weight: 500 !important;
        }
    }

    #maincontent #checkout .authentication-wrapper .action-auth-toggle {
        text-transform: uppercase;
        border-radius: 8px;
        padding: 10px 20px;
        background: $color-blue-primary;
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
        color: #fff;
        border: none;

        span {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.4rem;
        }

        &:hover {
            background: #006bb4;
        }
    }

    #checkout {

        .opc-progress-bar {
            //display: none;
        }

        .checkout-shipping-address .action.primary,
        .checkout-billing-address .action.primary,
        .modal-footer .action.primary,
        .actions-toolbar .action.primary,
        .checkout-shipping-address button.action,
        .checkout-billing-address button.action,
        .modal-footer button.action,
        .actions-toolbar button.action {

            text-transform: uppercase;
            border-radius: 8px;
            padding: 15px 25px;
            background: $color-blue-primary;
            box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
            color: #fff;
            border: none;

            span {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 1.4rem;
            }

            &:hover {
                background: #006bb4;
            }

        }

        .fieldset > .field:not(.choice) > .control {
            width: 100%;
            float: right;
        }

        .opc-wrapper .step-title, .opc-block-summary > .title, .opc-sidebar .step-title {
            font-weight: normal !important;
        }

    }

    .loading-mask .loader::after {
        border-top: 3px solid #005DAF !important;
    }

    .checkout-payment-method .payment-method-content {
        padding: 0px 0 40px 25px !important;
    }

    .opc-block-summary > .place-order:last-child .actions-toolbar {
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: 0px;
        margin-right: 0px;
    }

    #card-details-label {
        margin-bottom: 20px;
    }
}

.firecheckout #checkout [data-collapsible="true"] > [role="tab"], .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"], .firecheckout #checkout [data-collapsible="true"] > [role="tab"] > span, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] > span, .firecheckout #checkout [data-collapsible="true"] > [role="tab"] > strong, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] > strong, .firecheckout #checkout [data-collapsible="true"] > [role="tab"] .action-toggle, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] .action-toggle {
    color: #333 !important;
}

.firecheckout #checkout [data-collapsible="true"] > [role="tab"] > span::after, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] > span::after, .firecheckout #checkout [data-collapsible="true"] > [role="tab"] > strong::after, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] > strong::after, .firecheckout #checkout [data-collapsible="true"] > [role="tab"] .action-toggle::after, .firecheckout #checkout [data-collapsible="true"] > .title[data-role="title"] .action-toggle::after {
    border-color: #333 !important;
}

#tooltip-label {
    display: none;
}


.filter-image--holder {
    width: 100%;
    border: 1px solid #ddd;
    height: 350px;

    .filter-image {
        display: block;
        margin: 30px;
        height: 290px;
    }
}


.product-item {
    .filter-image-list--holder {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 201px;

        .filter-image-list {
            height: 133px;
            width: 133px;
        }
    }
}

/* Checkout Header */

.firecheckout.checkout-index-index {

    .header .header-logo .logo {
        max-width: 38%;
    }

    @include max-screen($screen__m)  {
         .header .header-logo {

            flex: 100;
            justify-content: center;

            .logo {
                max-width: 80%;
            }

         }
    }

    .header.content {
        height: 84px;
    }

    .header-search {

        .page-title-wrapper {
            margin-top: 0px !important;
        }

        .page-title {

            font-family: 'Montserrat', sans-serif;
            font-weight: 700 !important;
            font-size: 2.3rem;
            line-height: 3rem;
            color: #fff;
            text-transform: uppercase;
            text-align: center;

            &:after {
                content: 'Secured by GeoTrust SSL';
                font-size: 1rem;
                display: block;
                text-transform: none;
                font-weight: normal;
                line-height: 1rem;
            }

        }

        @include max-screen($screen__m)  {
            width: 20%;
            flex-basis: 50%;
        }
    }

    .header-account {

        align-items: flex-end;

        .continue-shopping {
            background: #347CC3;
            border: 1px solid #347CC3;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 700;
            padding: 7px 15px;
            font-size: 1.4rem;
            box-sizing: border-box;
            vertical-align: middle;
            text-transform: uppercase;
            border-radius: 8px;


            @include max-screen($screen__m) {
                display: none;
            }

            &:hover, &:focus {
                text-decoration: none;
                background: darken(#347CC3, 10%);
            }
        }
    }

    .tablet-help-info {
        display: none !important;
    }
}

.firecheckout.firecheckout-col1-set.fc-multistep .continue-shopping {
    display: block !important;
}

.firecheckout.firecheckout-col1-set.fc-multistep .continue-shopping {
    @include max-screen($screen__m) {
        display: none !important;
    }
}

.firecheckout #checkout {
    margin-top: 40px !important;
}

.firecheckout .place-order .actions-toolbar .primary {
    float: initial !important;
    display: block !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}


.cart.table-wrapper .actions-toolbar, .cart-container .form-cart {

    .action {

        &.continue, &.update {

            opacity: 1;
            background: $color-blue-primary;
            border-radius: 8px;
            border: none;
            color: #fff;
            padding: 15px 25px;
            text-transform: uppercase;
            font-weight: 400;

            &:hover, &:focus {
                text-decoration: none;
                background: darken($color-blue-primary, 10%);
                border:none;
                color: #fff;
            }

        }

    }

}

.action {

    &.continue, &.update {

        opacity: 1;
        background: $color-blue-primary;
        border-radius: 8px;
        border: none;
        color: #fff;
        padding: 15px 25px;
        text-transform: none;
        font-weight: normal;
        font-weight: 400;

        &:hover, &:focus {
            text-decoration: none;
            background: darken($color-blue-primary, 10%);
            border:none;
            color: #fff;
        }

    }

}



.cart.table-wrapper .actions-toolbar, .cart-container .form-cart {
    .action {

        &.action-edit, &.action-delete {

            opacity: 1;
            background: none;
            border-radius: 0px;
            border: none;
            color: $color-blue-primary;
            padding: 0px;
            text-transform: uppercase;
            font-weight: 400;

            &:hover, &:focus {
                text-decoration: none;
                color: darken($color-blue-primary, 10%);
                border:none;
                color: #fff;
            }

        }

    }
}

.action {

    &.action-edit, &.action-delete {

        opacity: 1;
        background: none;
        border-radius: 0px;
        border: none;
        color: $color-blue-primary;
        padding: 0px;
        text-transform: uppercase;

        &:hover, &:focus {
            text-decoration: none;
            color: darken($color-blue-primary, 10%);
            border:none;
        }

    }

}

.abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    color: #ffffff;
}

.am_shopby_link_selected {
    font-weight: normal !important;
}

.filter-options-content .items a {
    color: #333333;
    margin-left: 5px !important;
    position: relative;

    &:hover, &:focus {
        color: darken(#333333, 10%);
    }
}

.filter-options .filter-options-title {
    color: #333;
}

.action.clear.filter-clear {
    opacity: 1;
    background: $color-blue-primary;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 15px 25px;
    text-transform: uppercase;

    &:hover, &:focus {
        text-decoration: none;
        background: darken($color-blue-primary, 10%);
        border:none;
    }
}

.block-actions.filter-actions {
    display: block;
    float: left;
    margin-top: 20px;
}

.page-layout-2columns-left .page-main .columns {
    min-height: 850px;
}

.catalog-category-view {
    h1#page-title-heading {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
    }
}

/* Compare */

.catalog-product_compare-index,  {
    .actions-primary {
        display: none;
    }

    .cell.product.info {


		.actions-secondary {
			display: none;
		}

		.product-item-details {

			text-align: center;
			margin-top: -24px;

			.product-item-name {
				margin-left: 10px;
				margin-right: 10px;
				min-height: 45px;
				text-align: center;
				vertical-align: middle;
				display: flex;
				justify-content: center;
				align-items: center;

				a {
					color: #333;
					text-decoration: none;
					font-weight: bold;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

			.product-item-sku {
				font-size: 1.2rem;

				a {
					color: #333;
					text-decoration: none;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

		}

		.product-image-photo {
			max-width: 80%;
		}

		.price-box .price-excluding-tax .price {
			font-size: 2.5rem;
			font-weight: normal;
		}

		.price-including-tax {
			display:none;
		}

		.price-excluding-tax {

			&:before {
				display: none;
			}

			&:after {
				content: "(" attr(data-label) ")";
		    	font-size: 1.1rem;
					display: block;
					margin-top: 5px;
			}
		}

    }
}

.products-grid.wishlist {

    .product.info {


		.actions-secondary {
			display: none;
		}

		.product-item {

			text-align: center;
			margin-top: -24px;

			.product-item-name {
				margin-left: 10px;
				margin-right: 10px;
				min-height: 45px;
				text-align: center;
				vertical-align: middle;
				display: flex;
				justify-content: center;
				align-items: center;

				a {
					color: #333;
					text-decoration: none;
					font-weight: bold;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

			.product-item-sku {
				font-size: 1.2rem;

				a {
					color: #333;
					text-decoration: none;

					&:hover {
						text-decoration: none;
						color: darken(#333, 10%)
					}
				}
			}

		}

		.product-image-photo {
			max-width: 80%;
		}

		.price-box .price-excluding-tax .price {
			font-size: 2.5rem;
			font-weight: normal;
		}

		.price-including-tax {
			display:none;
		}

		.price-excluding-tax {

			&:before {
				display: none;
			}

			&:after {
				content: "(" attr(data-label) ")";
		    	font-size: 1.1rem;
					display: block;
					margin-top: 5px;
			}
		}

    }

}

/* My Accounts */

body.account {
    .columns {

        padding-top: 0;
        position: relative;
        z-index: 1;

        @include max-screen($screen__m) {
            .column.main {
                    order: 2;
            }
        }
    }

    .page-title-wrapper {
        margin-top: 40px;
        @include max-screen($screen__m) {
            margin-top: 0px !important;
        }
    }

    h1#page-title-heading {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .account-nav .item a {
        color: #333;

        &:hover, &:focus {
            color: darken(#333333, 10%);
            background: none !important;
        }
    }

    .account-nav .item.current a, .account-nav .item.current strong {
        border: none;
    }

    .account-nav .content {
        background:none;
        margin-top: 40px;
    }
}

.products-grid.wishlist .product-item .box-tocart .actions-primary {
    display: none;
}

.wishlist-index-index {
    .actions-toolbar {
        .primary {

            .action {
                width: 200px;
                display: inline-block;

                opacity: 1;
                background: $color-blue-primary;
                border-radius: 8px;
                border: none;
                color: #fff;
                padding: 15px 25px;
                text-transform: uppercase;

            }
        }
    }
}

@include max-screen($screen__m) {
    .wishlist-index-index.page-layout-2columns-left .page-main .columns .column.main .products-grid .product-item {
        width: 100%;
    }
}

.product-image-wrapper.hex-wrapper {

    border: 1px solid #ccc;

    .product-hex {
        margin: 5px !important;
        width: 27px !important;
        height: 27px !important;
    }
}

.page-layout-1column .page-main .columns {
    @include max-screen($screen__m) {
        flex-direction: column;
    }
}

.cart.item {
    .filter-image--holder {
        height: 100px;
        width: 110px;

        @include max-screen($screen__m) {
            height: 60px;
            width: 50px;
        }

        .filter-image {
            height: 90px;
            margin: 5px;

            @include max-screen($screen__m) {
                height: 50px;
            }
        }
    }
}

.amshopby-filter-current.filter-current {
    display: none;
}

.price-label {
    display: none;
}

select {
    font-family: 'Montserrat', sans-serif !important;
}

.cart.table-wrapper .actions-toolbar .action-gift, .cart.table-wrapper .actions-toolbar .action-towishlist {
    display: none;
}

.cms-page-view {

    .breadcrumbs {
        display: none;
    }

    .page-title {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .page-main {
        width: 880px;
        margin-top: 40px;
        @include max-screen($screen__m) {
            width: 100%;
        }
    }

    .page-main .columns .main {
        flex: 1;
    }
}

.catalogsearch-result-index {
    h1#page-title-heading {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
    }

    h1#page-title {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
    }
}

.cart-container .cart-summary #block-shipping {
    display: none;
}

/* Contact Page */

.contact-index-index {

    &.page-layout-2columns-left .page-main .columns .sidebar {
        background: none;
        flex: 0.25;
        &:before {
            background: none;
        }
    }

    &.page-layout-2columns-left .page-main .columns {
        padding-top: 40px;
    }

    .contact-details--wrapper {

        border:1px solid #E0E7E7;

        .contact-details--section {


            .contact-details--title {
                background: #F8F8F8;

                h4 {
                    margin: 0px;
                    padding: 18px 20px;
                }
            }

            .contact-details--content {
                padding: 20px;
            }
        }

    }

    .page-title-wrapper {
        h1 {
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 2.5rem;
            margin-bottom: 20px;
        }
    }

    .contact-form-wrapper {

        border:1px solid #E0E7E7;
        margin-top: 40px;

        h4 {
            background: #F8F8F8;
            margin: 0px;
            padding: 18px 20px;
        }

        .form {
            padding: 20px;
        }

        .fieldset > .field > .label, .fieldset > .fields > .field > .label {
            font-weight: normal;
        }

        p.file-details {
            font-size: 11px;
        }

        .fieldset > .field.required > .label::after, .fieldset > .field._required > .label::after, .fieldset > .fields > .field.required > .label::after, .fieldset > .fields > .field._required > .label::after {
            content: '*';
            color: #333333;
            font-size: 1.2rem;
            margin: 0 0 0 5px;
        }
    }

    .actions-toolbar {
        .primary {
            float:right;
        }
    }

    @include max-screen($screen__m) {

        &.page-layout-2columns-left .page-main .columns .column.main {
            order: 1;
        }

        &.page-layout-2columns-left .page-main .columns .sidebar {
            order: 2;
        }

        .actions-toolbar .primary {
            float: none;
        }
    }
}

.firecheckout .opc-block-summary > .place-order:last-child .actions-toolbar {
    margin-left: -35px !important;
    margin-right: 0px !important;
}

.page-header .switcher .options ul.dropdown::before, .page-footer .switcher .options ul.dropdown::before {
    right: 10px;
    left: initial;
}

.page-header .switcher .options ul.dropdown::after, .page-footer .switcher .options ul.dropdown::after {
    right: 10px;
    left: initial;
}

.page-header .switcher .options.active ul.dropdown, .page-footer .switcher .options.active ul.dropdown {
    left: -100px;
}

@include max-screen(1280px) {

    .header.content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .sections.nav-sections .nav-wrapper {
        padding-left: 20px;
    }

}

@include max-screen($screen__m) {
    .header.content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .sections.nav-sections .nav-wrapper {
        padding-left: 0px;
    }
}

.split-content .box-tocart {
  display: none;
}

.header .header-account .links li a.compare {
    border-right: 1px solid #ffffff;
    padding-right: 0.7rem;
    height: 15px;
}

.header .header-account .links li.compare {
    margin: 0px;
    border: 0px;
    padding-right: 0px;
}

.header .header-account .header-account-links #switcher-currency-nav {
    margin-top: -1px;
}

.page-layout-1column, .catalogsearch-result-index {
    .page-title-wrapper {

        h1 {
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 2.5rem;
            margin-bottom: 20px;
        }
    }
}

.checkout-cart-index, .checkout-onepage-success {
    .page-main {
        .page-title-wrapper {
            margin-top: 40px;
        }
    }
}

.cart-container .checkout-methods-items .action.primary {
    font-size: 1.4rem;
}

.block-search input {
    border-radius: 10px;
    padding-top: 12px;
    padding-bottom: 10px;
    height: 50px;
    border: 0;
    padding-left: 15px;
    font-family: Montserrat,sans-serif;
}

.customer-account-login, .customer-account-create {
    .page-main {
        .page-title-wrapper {
            margin-top: 40px;
        }
    }
}

.filter-options-title[aria-expanded='true'] {
    position: relative;
    &:after {
        position: absolute;
        content: "-";
        font-size: 1.8rem;
        margin-top: 5px;
        right: 0;
    }
  }

  .filter-options-title[aria-expanded='false'] {
    position: relative;
    &:after {
        position: absolute;
        content: "+";
        font-size: 1.8rem;
        margin-top: 5px;
        right: 0;
    }
  }

#search_autocomplete {
    display: none !important;
}