.homepage--hero {

    .homepage--hero--wrapper {
        background: #003F7D;
        background: radial-gradient(circle, rgba(0,63,125,0.88) 0%, rgba(0,63,125,1) 100%);
    }

    .homepage-hero {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        color: #fff;
        height: 500px;
    }

    .homepage-hero--top {

        width: 100%;
        padding-bottom: 56px;

        h2 {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            width: 600px;
            font-size: 3.2rem;
            text-align: center;
            margin: 0px auto;
            padding-top: 75px;
            margin-bottom: 40px;
            line-height: 4.5rem;
        }

        p {

            text-align: center;
            font-family: 'Kalam', cursive;
            font-weight: 700;
            font-size: 2.5rem;
            transform: rotate(-5deg) skew(-5deg);

            .underline {
                text-decoration: underline;
            }

            .five-star-holder {
                color: transparent;
                display: inline-block;
                position: relative;
                width: 123px;


                &:before {
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 900;
                    content: "\f005\f005\f005\f005\f005";
                    position: absolute;
                    left: 0;
                    font-size: 2.2rem;
                    color: #F8E71C;
                    top: -1px;
                    filter:drop-shadow(0px 1px 1px rgba(0,0,0,0.5));
                }

            }
        }

    }

    .homepage-hero--bottom {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        background: #F8F8F8;
        margin-top: -180px;

        @include max-screen($screen__m) {
            margin-top: -125px;
        }

        .homepage-hero--product-select {
            background-color: $color-blue-secondary;

            ul {
                display: flex;
                margin: 0px;
                padding: 0px;

                li {
                    display: flex;
                    list-style: none;
                    width: 50%;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1rem;
                    text-transform: uppercase;
                    height: 65px;
                    color: #ffffff;
                    margin: 0px;
                    padding: 0px;
                    background: #025CB5;


                    a {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 1.2rem;
                        text-transform: uppercase;
                        color: #ffffff;
                        font-weight: 700;
                        position: relative;
                    }
                }

                li.stock {

                    background: #025CB5;
                    align-content: flex-end;
                    justify-content: flex-end;

                    a {
                        padding-left: 100px;
                        display: flex;

                        justify-content: center;
                        align-items: center;
                        margin-right: 70px;

                        &:hover, &:focus {
                            text-decoration: none;
                            color: darken(#fff, 10%);
                        }

                        &:before {
                            background: url('../images/stock-gobo-header.svg');
                            content: "";
                            position: absolute;
                            left: 0;
                            width: 85px;
                            height: 55px;
                        }

                        &:after {
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 900;
                            content: "\f061";
                            position: absolute;
                            right: -20px;
                            font-size: 1.2rem;
                        }
                    }
                }

                li.custom {

                    background: linear-gradient(90deg, rgba(1,82,163,1) 0%, rgba(1,90,177,1) 55%, rgba(1,90,177,1) 100%);
                    align-content: flex-end;
                    justify-content: flex-start;

                    a {

                        padding-left: 100px;
                        display: flex;

                        justify-content: center;
                        align-items: center;
                        margin-left: 50px;

                        &:hover, &:focus {
                            text-decoration: none;
                            color: darken(#fff, 10%);
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            width: 85px;
                            height: 55px;
                            background: url('../images/custom-gobo-header.svg');
                        }

                        &:after {
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 900;
                            content: "\f061";
                            position: absolute;
                            right: -20px;
                            font-size: 1.2rem;
                        }

                    }
                }
            }
        }
    }

    .homepage-hero--category-list {

        display: flex;
        flex-direction: row;

        .category-list--details {
            width: 25%;

            padding: 30px;
            padding-left: 40px;

            h4 {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 2.3rem;
                line-height: 3rem;
            }

            a { margin-top: 20px; }
        }

        .category-list--listing {

            width: 75%;
            display: flex;
            justify-content: center;
            align-items: center;

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;

                li {
                    width: 25%;
                    list-style: none;
                    display: flex;

                    a {
                        color: $color-blue-primary;

                        &:hover, &:focus {
                            color: darken($color-blue-primary, 10%);
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .category-list--feature {
            width: 75%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 40px;

            .category-list--feature--wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .category-list--feature--item {

                }
            }
        }
    }

}

.homepage--blocks {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .homepage--block {

        width: 48%;

        h2 {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 2.3rem;
            line-height: 3rem;
        }

        p {

        }

        a {
            margin-top: 20px;
        }
    }

}

@include max-screen($screen__l) {

    .homepage--hero .homepage-hero--category-list {
        flex-direction: column;

        .category-list--details {
            width: auto;
        }

        .category-list--listing {
            width: auto;
        }

        .category-list--feature {
            width: auto;
            padding: 20px;

            .category-list--feature--wrapper {
                flex-wrap: wrap;
            }
        }
    }

}


@include max-screen($screen__m) {
    .homepage--hero .homepage-hero--top h2 {
        width: 300px;
        font-size: 2.8rem;
        padding-top: 45px;
    }

    .homepage--hero .homepage-hero--bottom .homepage-hero--product-select ul {

        flex-direction: column;

        li {
            width: 100%;

            a {
                &:after {
                    display: none;
                }
            }
        }

        li.stock {
            justify-content: center;
            align-items: center;
            margin: 0px auto;

            a {
                margin-right: 0px;
            }
        }

        li.custom {
            justify-content: center;
            align-items: center;

            a {
                margin-left: 0px;
            }
        }
    }

    .homepage--hero .homepage-hero--category-list {
        flex-direction: column;

        .category-list--details {
            width: auto;
            padding: 20px;
        }

        .category-list--listing {
            width: auto;

            ul {

                margin-top: 20px;

                li {
                    width: 50%;
                }
            }
        }

        .category-list--feature {
            width: auto;
            padding: 20px;

            .category-list--feature--wrapper {
                flex-wrap: wrap;
            }
        }
    }

    .homepage--blocks {
        flex-direction: column;

        .homepage--block {
            width: 100%
        }

    }

}



.homepage--featured {

    margin-top: 50px;
    margin-bottom: 20px;

    .block-title {
        strong {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 2.3rem;
            line-height: 3rem;
        }
    }

    .product-item-actions {
        display: none;
    }

}

.page-layout-1column .homepage--featured .block.widget {

        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 24%) / 5);
                padding: 0;
                width: 15%;

                &:nth-child(4n + 1) {
                    margin-left: calc((100% - 4 * 24%) / 5);
                }

                &:nth-child(6n + 1) {
                    margin-left: 0;
                }
            }
        }

        @include max-screen($screen__m) {

            .products-grid {

                .product-items {
                    margin: 0;
                }

                .product-item {

                    margin-bottom: 20px;
                    padding: 0;
                    width: 100%;
                    margin-left: 0 !important;

                    .product-image-container {
                        margin: 0px auto;
                        display: block;
                    }
                }

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }

                &:nth-child(5n + 1) {
                    margin-left: 0;
                }
            }

        }
    }