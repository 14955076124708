.footer.content {
	border:none;

	.copyright {
		margin-bottom: 20px;
	}
}

.footer-link-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
}

@include max-screen($screen__m) {

	.footer-link-wrapper {
		flex-direction: column;

		.footer-links-section {
			margin-bottom: 20px;
		}
	}

}