/* Shipping Table */

table.tableizer-table {
    font-size: 12px;
    border: 1px solid #efefef;
    width: 100%;

}

.tableizer-table tr:nth-child(even) {
    background-color: #efefef;
}

.tableizer-table .title {
    background-color: #fff;
    border: 0px;
}

.tableizer-table td {
    padding: 4px;
    margin: 3px;
    border: 1px solid #efefef;
}

.tableizer-table td {
    h4 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.tableizer-table td:nth-child(3),
.tableizer-table td:nth-child(4),
.tableizer-table td:nth-child(5),
.tableizer-table td:nth-child(6) {
    text-align: center;
}

.tableizer-table th:nth-child(3),
.tableizer-table th:nth-child(4),
.tableizer-table th:nth-child(5),
,
.tableizer-table th:nth-child(6) {
    text-align: center;
}

.tableizer-table th {
    background-color: #104E8B;
    color: #FFF;
    font-weight: bold;
    height: 40px;
    vertical-align: middle;
}


/* Related Products */


.page-layout-1column .products-grid.products-related .product-item {
    width: 166px;
}

.column.main .product-item {
    padding-left: 0px;
}


.products-related {

    .product-items {
        margin: 0px !important;
        display: flex;
        justify-content: space-between;
    }

    .product-item {

        border: 1px solid $color-grey-primary;
        position: relative;

        .actions-secondary {
            position: absolute;
            top: -1px;
            left: -1px;
            padding: 0px;
            z-index: 9999;

            a {
                border: 1px solid $color-grey-primary;
                margin: 0px;
                display: block !important;
                float: left;
                background: #ffffff;
            }

            &:after {
                clear: both;
                float: none;
            }
        }

        .product-item-info {
            width: 100%;
        }

        .product-image-container {
            display: initial !important;
        }

        .product-item-details {

            text-align: center;

            .product-item-name {
                margin-left: 10px;
                margin-right: 10px;
                min-height: 45px;
                text-align: center;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    color: #333;
                    text-decoration: none;
                    font-weight: bold;

                    &:hover {
                        text-decoration: none;
                        color: darken(#333, 10%)
                    }
                }
            }

            .product-item-sku {
                font-size: 1.2rem;
                margin-top: 10px;
                font-weight: normal;

                a {
                    color: #333;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                        color: darken(#333, 10%)
                    }
                }
            }

        }

        .product-image-photo {
            max-width: 80%;
        }

        .price-box .price-excluding-tax .price {
            font-size: 1.8rem;
        }

        .price-including-tax {
            display: none;
        }

        .price-excluding-tax {

            &:before {
                display: none;
            }

            &:after {
                content: "(" attr(data-label) ")";
                font-size: 1.1rem;
                display: block;
            }
        }

    }

}

/* Product Pages */



/* Product Page */

.product-add-form {
    h1.page-title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

#product_addtocart_form {
    max-width: 93vw;
}

.product-info-stock-sku .stock {
    display: none;
}

.product.attribute.sku {

    font-size: 1.2rem;
    margin-bottom: 20px;

    strong {
        display: inline-block;
        font-weight: normal;
    }

    div {
        display: inline-block;
    }
}


.product-info-split {
    .split-right {
        float: right;
        width: 25%;
        position: sticky;
        top: 1em;
    }

    .split-left {
        float: left;
        width: 72%;

        .split-media {
            float: left;
            width: 40%;
            order: 1;

            .product.media {
                width: 100%;

            }
        }

        .split-content {
            float: right;
            order: 2;
            width: 55%;
        }
    }

    .product-info-main {
        display: none;
    }
}

.product-custom-gobo {

    .split-left {
        float: left;
        width: 72%;

        .split-media {
            display: none;

        }

        .split-content {
            float: left;
            width: 62%;
        }

        .split-content-custom-types {
            width: 35%;
            float: right;
        }
    }

}

/* Media Viewer */

.product.media {

    .fotorama {
        overflow: visible;
    }

    .fotorama__stage {
        border: 1px solid $color-grey-primary;
    }

    .fotorama__nav-wrap {
        margin-top: 10px;
    }

    .fotorama__thumb-border {
        border: 1px solid darken($color-grey-primary, 25%);
    }

    .fotorama__thumb {
        overflow: visible !important;
    }

    .fotorama__thumb img {
        border: 1px solid $color-grey-primary  !important;
    }

    .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
        width: 89px;
    }


}


/* Custom Option */

.product-options-wrapper {
    border: 1px solid $color-blue-primary;
}

.options-header {
    background-color: rgba($color-blue-primary, 0.1);
    border-bottom: 1px solid $color-blue-primary;

    h4 {
        margin: 0px;
        color: $color-blue-primary;
        font-weight: bold;
        padding: 20px;
    }
}

.row-options--container {
    background-color: $color-grey-secondary;
    padding: 20px;
    position: relative;

    h4 {
        margin: 0px;
    }

    span.help-tooltip {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

.product-add-form {

    .product-options-wrapper {

        .fieldset {

            .field {

                &:after {
                    clear: both;
                }

                margin-bottom: 0px;

                label.label,
                label {
                    float: left;
                    width: 30%;

                    span {
                        padding: 20px;
                        display: block;
                        height: 32px;
                        display: table-cell;
                        vertical-align: middle;
                    }
                }

                .control {

                    float: right;
                    width: 55%;
                    padding: 20px;

                    &.control-lantern {
                        width: 100%;
                        padding: 0px;
                        min-height: 350px;
                    }
                }



            }

        }

        .lantern-option-container {

            .field,
            .model-additional-details {

                float: left;
                width: 100%;

                &:after {
                    clear: both;
                }

                label {
                    float: left;
                    width: 30%;

                    span {
                        padding: 20px;
                        display: block;
                        font-weight: bold;
                        height: 32px;
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }

            .model-additional-details {
                p {
                    float: right;
                    width: 59%;
                    font-size: 1.3rem;

                    strong {
                        font-weight: bold;
                    }

                    span {
                        margin-left: 5px;
                    }
                }
            }

            .lantern-message {
                font-size: 1.3rem;

                p {
                    padding: 20px;
                }
            }


        }


    }

}

.product.info.detailed {
    margin-top: 20px;
    float: left;
    width: 100%;
}

/* Purchase Lsider */

.purchase-slider {

    border: 1px solid $color-grey-primary;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    top: 0 !important;

    .price-final_price {

        display: flex;
        flex-direction: column;

        .price-wrapper {}

        .price-including-tax {

            display: block;
            order: 2;
            text-align: center;

            span.price {
                font-weight: normal;
            }

            &:before {
                display: none;
            }

            &:after {
                content: attr(data-label);
                font-size: 1.1rem;
                margin-left: 5px;
            }

        }

        .price-excluding-tax {

            display: block;
            order: 1;
            text-align: center;

            span.price {
                font-size: 3.5rem;
                font-weight: 400;
            }

            &:before {
                content: attr(data-label) ": ";
                font-size: 1.1rem;
                display: none;
            }

        }
    }

}

// .purchase-slider._sticky {
// position: fixed;
// width: 268px;

// @include max-screen($screen__m) {
//     position: initial;
//     width: initial;
// }

// }

.product-options-bottom {

    .box-tocart {

        width: 100%;

        .field.qty {
            display: flex;
            padding-right: 0px;

            flex-direction: row;
            align-items: center;
            justify-content: center;

            .label,
            .control {
                display: flex;
            }

            .label {
                margin-right: 20px;

                span {
                    vertical-align: middle;
                    font-weight: normal;
                }
            }

            .control {
                input {
                    border-radius: 10px;
                    //@include box-shadow(0px,2px,4px,0, rgba(0,0,0,0.5));
                }
            }
        }

        .actions {
            width: 100%;
            display: flex;
            padding-top: 10px;
            margin-bottom: 20px;

            button.action {
                width: 100%;
            }
        }
    }

}

.promo--item {

    position: relative;
    padding-left: 40px;
    margin-bottom: 25px;

    &:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: "\f058";
        position: absolute;
        left: 0;
        font-size: 2.3rem;
        color: #258001;
    }

    h5 {
        color: #258001;
        font-size: 1.3rem;
        margin: 0px;
        margin-bottom: 0px;
        margin-bottom: 10px;
        line-height: 15px;
    }

    p {
        font-size: 1.3rem;
    }

    &.promo--item--no-desc {
        h5 {
            line-height: 3;
        }
    }

    &.out-of-stock {
        &:before {
            display: none;
        }

        h5 {
            color: #cccccc;
        }
    }
}

.promo--security {
    text-align: center;
    font-size: 1.3rem;
    color: $color-blue-primary;
}

.action.primary {
    border-radius: 10px;
    background: $color-blue-primary;
    border: none;

    //@include box-shadow(0px,2px,4px,0, rgba(0,0,0,0.5));

    &:hover,
    &:focus {
        background: darken($color-blue-primary, 10%);
        border: none;
    }

    span {
        text-transform: uppercase;
        font-weight: 400;
    }
}

/* Product Tabs */

.product.data.items {
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
}

.product.data.items>.data.item.title {
    margin: 0px;

    @include max-screen($screen__m) {
        border-top: 0px !important;
    }

    a.data.switch {
        padding: 20px 25px;
        border: 1px solid #F6F6F6;
        background-color: #F6F6F6;
        border: none;
        color: #333333;
    }
}

.product.data.items>.data.item.title.active {
    margin: 0px;

    a.data.switch {
        padding: 20px 25px;
        border: 1px solid #F6F6F6;
        background-color: #ffffff;
        border-top: 0px;
        border-bottom: 0px;
        border: none;
        color: $color-blue-primary;
    }
}

.product.data.items>.item.content {
    margin-top: 59px;
    border-top: none;
}

.product.data.items::before,
.product.data.items::after {

    content: '';
    display: table;
    margin-bottom: -5px;

}

/* Related Products */

.block.related {
    width: 100%;
    float: left;
}


/* Custom Gobo Types */

.intro-desc {
    margin-bottom: 20px;
}

.product.data.items::before {
    content: '';
    display: table;
    margin-top: 5px;
}

.product.data.items::after {
    content: '';
    display: table;
    margin-bottom: -5px;
}


.gobo-types {

    border: 1px solid #E0E7E7;

    h4 {
        background: #F8F8F8;
        padding: 20px;
        margin: 0px;
        line-height: 2rem;
        border-bottom: 1px solid #E0E7E7;
    }

    .gobo-types--type {
        padding: 20px;
        border-bottom: 1px solid #E0E7E7;

        h5,
        p {
            text-align: center;
        }

        h5 {
            font-size: 1.4rem;
            margin: 0px;
            margin-bottom: 5px;
        }

        .gobo-types--details {
            padding-left: 100px;
            position: relative;
            min-height: 96px;

            dl {
                padding-top: 26px;
                font-size: 1.3rem;

                dt {
                    display: inline-block;
                }

                dd {
                    display: inline;
                }

            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                width: 96px;
                height: 96px;
            }
        }

    }

    .gobo-types--metal {
        &:before {
            background: url('../images/gobo-1.png');
        }
    }

    .gobo-types--bl {
        &:before {
            background: url('../images/gobo-2.png');
        }
    }

    .gobo-types--glass1 {
        &:before {
            background: url('../images/gobo-3.png');
        }
    }

    .gobo-types--glass2 {
        &:before {
            background: url('../images/gobo-4.png');
        }
    }

    .gobo-types--glass3 {
        &:before {
            background: url('../images/gobo-5.png');
        }
    }

    .gobo-types--multi {
        &:before {
            background: url('../images/gobo-6.png');
        }
    }

}


.help-tooltip {
    @include lib-tooltip($_tooltip-position : bottom,
        $_tooltip-selector-content: '.help-tooltip.content',
        $_tooltip-selector-toggle : '.help-tooltip.toggle'
    );

    .help-tooltip.content {
        width: 500px !important;
    }
}

.fieldset>.field.required>.label::after {
    display: none !important;
}

.product-add-form .product-options-wrapper .fieldset .field label.label span,
.product-add-form .product-options-wrapper .fieldset .field label span {
    font-weight: normal;
}

.product-options-wrapper .field .note {
    &:before {
        display: none;
    }

    font-style: italic;

    strong {
        font-weight: normal;
    }
}

.custom-gobo--signoff {

    background-color: #E5EEF7;
    float: left;
    padding-bottom: 10px;
    padding-left: 75px;
    position: relative;

    border-top: 1px solid #025CB5;
    border-bottom: 1px solid #025CB5;

    &:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: "\f058";
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 2.8rem;
        color: #025CB5;
    }

    h5 {
        font-size: 1.4rem;
    }

    p {}
}

@include max-screen($screen__l) {

    .product-custom-gobo {

        .split-left {

            .split-content {
                float: none;
                width: 100%;
            }

            .split-content-custom-types {
                float: none;
                width: 100%;
            }
        }
    }

}

@include max-screen($screen__m) {

    .product-info-split {


        .split-right {
            float: none;
            width: 100%;
            position: relative;
            top: auto;
        }

        .split-left {
            float: none;
            width: 100%;

            .split-media {
                float: none;
                width: 100%;
                order: 1;

                .product.media {
                    width: 100%;

                }
            }

            .split-content {
                float: none;
                width: 100%;
                order: 2;
            }
        }

    }

    .product-custom-gobo {

        .split-left {
            float: none;
            width: 100%;

            .split-media {
                display: none;

            }

            .split-content {
                float: none;
                width: 100%;
            }

            .split-content-custom-types {
                float: none;
                width: 100%;
            }
        }

        .split-right {
            float: none;
            width: 100%;
            position: relative;
            top: auto;
        }
    }

    // ._sticky {
    // position: initial;
    // width: auto;
    // }

    .purchase-slider {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .product.data.items>.item.title>.switch {
        font-size: 1.4rem;
        line-height: 20px;
        height: auto;
    }

    .product.data.items>.item.content {
        margin-top: 0px;
        border: none;
        overflow-x: scroll;
    }

    .product.data.items>.item.title {
        border: 1px solid #E0E7E7;
        border-left: 0px;
        border-right: 0px;
    }

    .products-related {
        .product-items {
            display: flex;
            flex-direction: column;

            .product-item {
                width: 100% !important;
                margin-bottom: 20px;
            }
        }
    }

}



.product-add-form .product-options-wrapper .fieldset .field {
    &:after {
        content: '';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}


.lantern-additional-cost {

    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
    clear: both;
    font-size: 1.3rem;

    p {
        border: 1px solid #e5b225;
        padding: 10px;
        background: #FFF2D8;
        margin-bottom: 0px;
    }
}

.page-title-wrapper.product {
    margin-top: 0px;
}

.product.attribute.sku strong::after {
    margin-right: 5px;
    content: ':';
    font-weight: normal;
}