.page-wrapper {
    @include max-screen($screen__m) {
        overflow: hidden;
    }

    .page-header {
        background: $color-blue-primary;
    }
}


.header {

    &.content {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 0px;
        height: 112px;
    }

    .header-logo {

        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .logo {
            float: none;
            position: relative;
            max-width: 100%;
            margin: 0px;

            img {
                max-width: 175px;
                max-height: 77px;
            }
        }
    }

    .header-search {

        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .block-search {
            width: 100%;

            @include min-screen($screen__m) {
                padding: 0;
            }

            .control {
                padding: 0px;
            }

            .nested {
                display: none;
            }

            .block-search input::placeholder {
                font-family: 'Montserrat', sans-serif;
            }

            .amsearch-form-container.-opened .input-text::placeholder {
                font-style: none;
            }

            .amsearch-form-container .control {
                min-height: 50px;
            }

            .amsearch-form-container .input-text:focus {
                box-shadow: none;
            }

            .search-autocomplete.-bottom-position {
                bottom: inherit;
                top: calc(100% + -16px);
            }

            .amsearch-form-container.-opened .input-text::placeholder {
                color: #cecece;
                font-style: normal;
            }

            .amsearch-wrapper-input {
                input {
                    border-radius: 10px;
                    padding-top: 12px;
                    padding-bottom: 10px;
                    height: 50px;
                    border: 0px;
                    padding-left: 15px;
                    font-family: 'Montserrat', sans-serif;
                }
            }

            .amsearch-form-container.-opened .input-text {
                padding-left: 34px !important;
            }

            .amsearch-results .amsearch-item .item-name {
                font-size: 1.5rem;
            }

            .action.search {
                opacity: 1;
                height: 100%;
                margin: 0;
                padding: 0;
                right: 0;
                background: #347CC3;
                border-radius: 8px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                top: 0;

                &:before {
                    display: none;
                }

                &:hover,
                &:focus {
                    background: darken(#347CC3, 20%);
                    text-decoration: none;
                }

                span {
                    position: relative;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: bold;
                }

            }
        }

        .tablet-help-info {

            &:before {

                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                content: "\f095";
                position: absolute;
                left: 0;
                font-size: 1.4rem;
                color: #ffffff;

                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);

            }

            padding-left: 20px;
            font-size: 1.2rem;
            font-weight: 400;
            color: #fff;
            display: block;
            position: relative;

            margin-top: 15px;

            @include min-screen($screen__m) {
                margin-top: 0px;
            }

            a {
                color: #F8E71C;

                &:hover,
                &:focus {
                    color: darken(#F8E71C, 10%)
                }
            }
        }
    }

    .header-account {

        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        order: 3;

        .header-account-links {
            display: flex;
            align-self: flex-end;

            .header.links {}

            #switcher-currency-nav {
                font-size: 1.2rem;
                margin-bottom: 0px;
                color: #fff;
                display: flex;
                padding-top: 1px;
                margin-right: 0px;

                .switcher-trigger {

                    display: flex;

                    strong {
                        display: flex;

                        span {

                            &:hover,
                            &:focus {
                                color: darken(#ffffff, 20%);
                                text-decoration: none;
                            }
                        }
                    }
                }

                .dropdown.switcher-dropdown {
                    border-radius: 5px;
                    border: 1px solid $color-grey-primary;

                    a {
                        color: $color-blue-primary;

                        &:hover,
                        &:focus {
                            color: darken($color-blue-primary, 10%);
                        }
                    }
                }
            }
        }

        .links {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0px;
            align-self: flex-end;

            li {

                &:first-child {
                    padding-left: 0px;
                }

                margin-right: 0.7rem;
                border-right: 1px solid #ffffff;
                padding-right: 0.7rem;
                height:15px;

                /*&:after {
                    content: '|';
                    padding-left: 6px;
                    color: #ffffff;
                }*/

                a {
                    color: #ffffff;
                    font-size: 1.2rem;
                    float: left;

                    &:hover,
                    &:focus {
                        color: darken(#ffffff, 20%);
                        text-decoration: none;
                    }
                }

                &.greet {
                    display: none;
                }
            }
        }

        .minicart-wrapper {
            display: flex;
            align-self: flex-end;
            margin: 0px;

            .action.showcart {

                &:before {
                    display: none;
                }

                &:hover,
                &:focus {
                    color: darken(#ffffff, 20%);
                    text-decoration: none;
                }

                .counter.qty {
                    background: none;
                    font-weight: 400;
                    font-size: 1.4rem;
                }
            }
        }

        a.action.compare.no-display {
            display: inline-block !important;
        }
    }
}

.sections {

    &.nav-sections {
        background: $color-blue-primary;
        border-top: 1px solid $color-blue-secondary;
        position: relative;

        .nav-wrapper {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            display: flex;
            height: 55px;
        }

        .nav-content {
            flex: 30;
            justify-content: center;
            align-content: center;
            display: flex;
            flex-direction: column;

            border-left: 1px solid $color-blue-secondary;
            padding-left: 20px;
            float: right;

            p {
                margin: 0 auto;

                a {
                    color: #ffffff;

                    &:hover,
                    &:focus {
                        color: darken(#ffffff, 20%);
                    }
                }
            }

            span {
                color: #ffffff;
                position: relative;

                a {
                    color: #ffffff;

                    &:hover,
                    &:focus {
                        color: darken(#ffffff, 20%);
                    }
                }
            }

            span.call {
                &:before {

                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 900;
                    content: "\f095";
                    position: absolute;
                    left: 0;
                    font-size: 2rem;
                    color: #ffffff;

                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);

                }

                padding-left: 30px;
                font-size: 1.3rem;
                font-weight: 600;
            }

            span.reviewed {

                a {
                    &:before {

                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 900;
                        content: "\f005\f005\f005\f005\f005";
                        position: absolute;
                        left: 0;
                        font-size: 1.2rem;
                        color: #F8E71C;
                        top: -1px;
                        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                    }
                }


                display: block;
                margin-left: 30px;
                padding-left: 75px;
                font-size: 1.1rem;
                text-style: italic;
            }
        }
    }
}

/* Mega Menu */

.nav-sections-item-content {


    .main-menu__mobile {
        display: none;
    }

    .main-menu {
        display: flex;
        flex: 70;

        ul {
            display: flex;
            margin: 0px;
            padding: 0px;
            flex: 1;

            &>li {
                margin: 0px;
                display: flex;
                margin-right: 40px;

                &>a {
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: normal;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 15px;
                    line-height: 31px;
                    flex: 1;
                    text-align: center;
                    align-self: center;
                    position: relative;
                    font-weight: bold;

                    &:hover,
                    &:focus {
                        color: darken(#ffffff, 10%);
                        text-decoration: none;
                        font-weight: bold;
                    }
                }

                &:hover,
                &:focus {

                    .main-menu__mega,
                    ul {
                        display: block;
                    }
                }
            }

            &>li.main-menu__item--parent {

                &:hover,
                &:focus {

                    >a {

                        &:after {
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 900;
                            content: "\f0d7";
                            position: absolute;
                            left: 45%;
                            font-size: 2.5rem;
                            color: $color-blue-secondary;
                            bottom: -26px;

                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            -ms-transform: rotate(180deg);
                            -o-transform: rotate(180deg);
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .main-menu__item--parent {
        ul {
            display: none;
        }
    }

    .main-menu__item>.main-menu__mega {
        background: $color-blue-primary;
        border-top: 1px solid $color-blue-secondary;
        border-bottom: 1px solid $color-blue-secondary;
        position: absolute;
        top: 55px;
        left: 0px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        display: none;
        z-index: 999;

        >ul {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
        }

        ul.main-menu__inner-list {

            display: flex;

            li.main-menu__inner-item--all {
                display: flex;
                width: 20%;

                a {
                    align-self: flex-start;
                    text-transform: none;
                    font-weight: normal;
                    font-family: 'Montserrat', sans-serif;
                }
            }

            li.menu-wrapper {

                display: flex;
                width: 80%;

                ul.main-menu__inner-list--level2 {

                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .main-menu__inner-item--all {
                        display: none !important;

                        a {
                            text-transform: none;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: normal;
                        }
                    }

                    .main-menu__inner-item--level2 {
                        margin: 0px;
                        width: 25%;
                        display: flex;

                        a {
                            text-transform: none;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: normal;
                            text-align: left;
                        }
                    }


                }
            }
        }
    }

    .main-menu__inner-item--level1 {
        ul {}
    }

    .main-menu__mobile-only {
        display: none;
    }
}

.navigation .level0>a.level-top {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: normal;

    &:hover,
    &:focus {
        color: darken(#ffffff, 10%);
        text-decoration: none;
    }
}

.navigation .level0.active>.level-top,
.navigation .level0.has-active>.level-top {
    border: none;
    color: #ffffff;
    font-weight: bold;
}

.nav-sections {
    margin-bottom: 0px;
}

/* Header Responsive */

/* Tablet */

@include max-screen(1238px) {
    .sections.nav-sections .nav-content {
        display: none;
    }
}

@include max-screen(1150px) {
    .header-account {

        .header-account-links {
            flex-direction: column;

            .item.link.compare {
                border-right: 0px;
                padding-right: 0px;
                margin-right: 0px;
            }

            #switcher-currency-nav {
                align-self: flex-end;
            }
        }

    }
}

@include max-screen($screen__l) {
    .page-wrapper .page-header {
        //background: red;
    }
}

/* Mobile */

@include max-screen($screen__m) {
    .page-wrapper .page-header {
        border: none;
        margin: 0px;
    }

    .header.content {
        flex-flow: row wrap;
        height: auto;
        padding: 10px;
    }

    .panel.header-account {
        order: 2;
        width: 50%;
        flex: 1;
        margin-right: 60px;

        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .header-account-links {
            align-self: center;
        }
    }

    .panel.header-search {
        order: 3;
        width: 100%;
        flex: 1;
        flex-basis: 100%;
    }

    .panel.header-logo {
        order: 1;
        flex: 1.5;
        padding: 0px !important;
        margin: 0px !important;
    }

    .header .header-account .links {

        margin-right: 25px;

        .link.wishlist,
        .item.link.compare,
        .greet.welcome {
            display: none;
        }

        li {
            border: none;
            margin: 0px;
            height: auto;

            a {
                font-size: 0px;

                &:before {
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 300;
                    content: '\f007';
                    color: #fff;
                    font-size: 27px;
                    line-height: 27px;
                }
            }
        }
    }

    .panel.header-logo {
        flex: 0.9;
        flex-direction: column;
        margin-top: 30px;
        padding-left: 60px;

        .logo {
            max-width: 140px;

            img {
                max-width: 140px;
                width: 140px;
            }
        }
    }

    .action.nav-toggle {
        position: absolute;
        right: 15px;
        left: auto;
        top: 28px;

        &:before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            content: '\f0c9';
            color: #fff;
            font-size: 32px;
            line-height: 32px;
        }
    }

    .nav-open .nav-sections {
        width: 80%;
        right: 0;
        position: fixed;
        left: auto;
        z-index: 10000;
    }

    .nav-open .nav-toggle::after {
        background: rgba(0, 0, 0, 0);
    }

    .nav-open .page-main::after {
        background: rgba(0, 0, 0, 0.5);
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99;
    }

    .nav-sections {
        height: 100%;
        width: 80%;
        position: fixed;
        z-index: 999;
        top: 0;
        right: -80%;
        background-color: #111;
        overflow-x: hidden;
        padding-top: 60px;
        transition: 0.5s;
        -webkit-overflow-scrolling: touch;
        -webkit-transition: right 0.5s;
        transition: right 0.5s;
    }

    .nav-open .page-wrapper {
        left: auto;
    }

    .minicart-wrapper {

        align-self: center !important;

        .action.showcart {

            .counter-label-custom {
                display: none;
            }

            .counter.qty {

                width: 25px;
                height: 30px;
                line-height: 32px;
                margin: 0px;
                padding: 0px;
                overflow: visible;
                display: flex;


                &:before {
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 300;
                    content: '\f290';
                    color: #fff;
                    font-size: 26px;
                    position: absolute;
                }

                .counter-number-custom {
                    position: absolute;
                    border-radius: 15px;
                    background-color: #347CC3;
                    display: block;
                    font-size: 11px;
                    line-height: 11px;
                    padding: 3px 6px;
                    bottom: -4px;
                    right: -3px;
                }
            }

        }

    }

    /* Search */


    .header-search {
        .block-search .control {
            border: none;
            margin: 0px;
            z-index: 1;
        }

        .block-search input {
            position: relative;
            left: auto;
            margin: 0;
        }

        .block-search .label {
            display: none;
        }

        #search_mini_form {
            position: relative;
        }

        .form.minisearch>.actions {
            opacity: 1;
            height: 100%;
            margin: 0;
            padding: 0;
            right: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            top: 0;
            position: absolute;
            z-index: 999;
        }

        .block-search .action.search {

            &:after {
                font-family: 'Font Awesome 5 Pro';
                font-weight: 600;
                content: '\f002';
                color: #fff;
                font-size: 26px;
                /* margin-top: 7px; */
                position: absolute;
                top: 16px;
                right: 11px;
                -webkit-font-smoothing: antialiased;
            }

            position: relative;
            z-index: 99;
            display: block;
            background-color: #347CC3;
            width: 50px;
            border: none;


            span {

                display: none;

            }
        }
    }

    /* Side Nav */

    .main-menu__mobile-close-toggle {

        position: absolute;
        right: 0;
        top: -10px;

        .nav-toggle {
            &:before {
                font-family: 'Font Awesome 5 Pro';
                font-weight: 500;
                content: '\f00d';
                color: #fff;
                font-size: 32px;
                line-height: 32px;
            }
        }
    }

    .sections.nav-sections .nav-content {
        display: none;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-items {
        padding: 20px;

        .navigation li.level0,
        .navigation .level0 {
            border: none;

            &:last-child {
                border: none;
            }
        }

        .navigation a,
        .navigation a:hover {
            color: #fff;
        }

        .navigation .submenu:not(:first-child) ul>li a {
            color: #fff;
        }
    }

    .main-menu__list {
        flex-direction: column;
        z-index: 999;
    }

    .nav-sections {
        display: none;
    }

    .nav-open .nav-sections {
        padding-top: 0px;
        display: block;
    }

    .nav-sections-item-content {
        margin-top: 40px;
    }

    .sections.nav-sections .nav-wrapper {
        height: auto;
    }

    .nav-sections-item-content .main-menu {
        flex-direction: column;
    }

    .nav-sections-item-content {
        .main-menu__list {
            .main-menu__item {
                margin: 0px;
                margin-bottom: 15px;
                margin-bottom: 15px;

                >a {
                    text-align: left;
                    font-size: 1.8rem;
                }
            }
        }

        .main-menu__mobile-only {

            border-top: 1px solid #fff;
            margin-top: 20px;
            padding-top: 20px;

            ul li>a {
                text-align: left;
                font-size: 1.8rem;
            }
        }

    }

    .nav-sections .switcher {
        border: none;
        padding: 0px;
        font-weight: normal;
    }

    .nav-sections .switcher:last-child {
        border: none;
    }

    .nav-sections .switcher .label {
        display: none;
    }

    .nav-sections .switcher {
        color: #fff;
        margin-top: 15px;
        font-size: 1.8rem;

        strong {
            font-weight: normal;
        }
    }

    .main-menu__mobile-only {
        display: block;
    }

    .nav-sections-item-content {
        ul.dropdown.switcher-dropdown {
            display: flex;
            flex-direction: row;
            display: none;
        }
    }

    .nav-sections .switcher-trigger strong::after {
        display: none;
    }

}

.breadcrumbs {
    margin-top: 20px;
}


@include max-screen($screen__m) {

    .nav-sections-item-content .main-menu ul>li.main-menu__item--parent:focus>a::after,
    .nav-sections-item-content .main-menu ul>li.main-menu__item--parent:hover>a::after {
        display: none;
    }

    .nav-sections-item-content .main-menu__item>.main-menu__mega {
        display: none;
    }

    .nav-sections-item-content .main-menu ul>li:focus .main-menu__mega,
    .nav-sections-item-content .main-menu ul>li:focus ul,
    .nav-sections-item-content .main-menu ul>li:hover .main-menu__mega,
    .nav-sections-item-content .main-menu ul>li:hover ul {
        display: none;
    }

}